import React from "react"

import Seo from "../components/Seo"
import SiteHeader from "../components/SiteHeader"
import ContentHeader from "../components/ContentHeader"
import TopImageContainer from "../components/TopImageContainer"
import BrandsWeChangedSection from "../components/BrandsWeChangedSection"
import LetsConnectSection from "../components/LetsConnectSection"
import SiteFooter from "../components/SiteFooter"

import HomeHero from "../components/Home/HomeHero"
import ServicesSection from "../components/Home/HomeServicesSection"
import ForRestaurants from "../components/Home/ForRestaurants"

// import ClientsQuotesSection from "../components/ClientsQuotesSection"

import content from "../data/Content"

export default function Home() {
  return (
    <div className="content">
      <Seo page={content("home")} />
      <TopImageContainer>
        <SiteHeader content={content("header")} />
        <HomeHero content={content("home")} />
      </TopImageContainer>
      <ContentHeader content={content("home")} bgColor="#FFFFFF" />
      <ForRestaurants content={content("home")} />
      <ServicesSection content={content("home")} />
      <BrandsWeChangedSection content={content("home")} />
      {/* <ClientsQuotesSection content={content("home")} /> */}
      <LetsConnectSection content={content("home")} />
      <SiteFooter content={content("footer")} />
    </div>
  )
}
