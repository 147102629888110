import React from "react"
import Css from "./ForRestaurants.module.css"
import { Link } from "gatsby"

const ForRestaurants = props => {
  let data = props.content["for-restaurants"]

  return (
    <div className={Css.SectionForRestaurants}  >
        <div className={Css.Back}>
            <div className={Css.Texts}>
                <p className={Css.TextOne}>
                    {data["textOne"]}
                </p>
                <p className={Css.TextTwo}>
                    {data["textTwo"]}
                </p>
                <p className={Css.TextThree}>
                    {data["textThree"]}
                </p>
                <p className={Css.TextFour}>
                    <Link to={data["action"]}>
                        
                            {data["textFour"]}
                    </Link>
                </p>
            </div>
        </div>
    </div>
  )
}

export default ForRestaurants