import React from "react"
import Css from "./BrandsWeChangedSection.module.css"
import { Link } from "gatsby"

const BrandsWeChangedSection = props => {
  let brandsItems = []
  let data = props.content["brands-section"]
  if (data && data["brands"]) {
    brandsItems = data["brands"].map((brand, idx) => {
      let cssInfoSide = (idx / 2 === 0) ? Css.InfoLeft : Css.InfoRight

      return <div key={idx} className={Css.BrandItem} style={{ backgroundImage: `url( ${require('../images/' + brand.image)} )` }}>
        <Link className={Css.BrandItemLink} to={`/${brand.page}`} >
          <div className={[cssInfoSide, Css.Info].join(' ')} >
            <div className={Css.InfoTitle}>{brand.name}</div>
            <div className={Css.InfoDesc}>{brand.description}</div>
            <div className={Css.InfoAction} to={`/${brand.page}`} >
              {data.action} &gt;&gt;
            </div>
          </div>
        </Link>
      </div >

    })
  }

  return (
    <div className={Css.BrandsWeChangedSection}  >
      {data["title1"] &&
        <div className={Css.TitleBig} >{data["title1"]}</div>
      }
      {data["brands"] &&
        <div className={Css.BrandsContainer} >{brandsItems}</div>
      }
    </div>
  )
}

export default BrandsWeChangedSection