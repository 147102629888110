import React from "react"
import Css from "./HomeHero.module.css"
import { Link } from "gatsby"

const HomeHero = props => {
  let data = props.content["hero"]

  return (
    <div className={Css.HomeHero}>
      <div className={Css.TitleContainer} >
        <div className={Css.TitleBig} >{data["title1"]}</div>
        <div className={Css.TitleSmall} >{data["title2"]}</div>
        <div className={Css.TitleBig} >{data["title3"]}</div>
        <div className={Css.Subtitle}>{data["subtitle"]}</div>
      </div>
      <div className={Css.ActionButtonContainer} >
        <Link to="/contact">
          <div className="action-button" >{data["action"]}</div>
        </Link>
      </div>
    </div>
  )
}

export default HomeHero