import { Link } from "gatsby"
import React from "react"
import Css from "./HomeServicesSection.module.css"

const ContentHeader = props => {
  let data = props.content["services-section"]

  let servicesBlocks = []
  if (data["services"]) {
    servicesBlocks = data["services"].map((service, idx) => {
      return <div key={idx} className={Css.ServiceItem}>
        <div className={Css.ServiceIcon}><img src={require('../../images/' + service.icon)} alt={service.name} /></div>
        <div className={Css.ServiceTitle}>{service.name}</div>
        <div className={Css.ServiceDesc}>{service.description}</div>
      </div>
    })
  }

  return (
    <div className={Css.ServicesSection}  >
      <div className={Css.TitleBig} >{data["title1"]}</div>
      <div className={Css.Text} >{data["text"]}</div>
      <div className={Css.ServicesContainer}>{servicesBlocks}</div>
      <div className={Css.ActionButtonContainer}>
        <Link to="/services">
          <div className="action-button" >{data["action"]}</div>
        </Link>
      </div>
    </div>
  )
}

export default ContentHeader